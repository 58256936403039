import React from "react";
import styled from "styled-components";
// Components
import Member from "../Elements/Member";



import PrabinImage from "../../assets/img/team/prabin.jpg";
//import DiegoImage from "../../assets/img/team/diego.jpg";
import RakeshImage from "../../assets/img/team/rakesh.jpg";
import BishnuImage from "../../assets/img/team/bishnu.jpg";
import PrabeshImage from "../../assets/img/team/prabesh.jpg";
import PramodImage from "../../assets/img/team/pramod.jpg";
import PawanImage from "../../assets/img/team/pawan.jpg";
export default function Team() {
  const members = [
    {
      image:PrabinImage,
      name: "Prabin Niraula",
      title: "Director / Founder",
      about:`Prabin, an Australian migrant from Nepal, embarked on a journey of knowledge and growth, earning a Master's in Accounting and Finance while pursuing ACCA certification. Transitioning from banking to hospitality, he embraced new challenges. Amidst his travels, his passion for writing emerged, resulting in his debut book, "Triraskrita," a work of Spiritual Science Fiction. While successful in writing, Prabin found fulfillment in hospitality and is known for his sociable nature. He is a co-founder and team member, celebrated for his diverse talents and unwavering commitment. Prabin's evolving journey in literature and the hospitality industry continues to make a positive impact.`     
    },
    {
      image:RakeshImage,
      name: "Rakesh Subba",
      title: 'Director',
      about: `Rakesh Subba, a valued Shareholder at Aussie Everest Group Pty Ltd, hails from Nepal and has carved a culinary journey that spans the globe, landing him in the vibrant culinary scene of Australia. His culinary passion began in his youth, culminating in a diploma in Culinary Arts from the National Institute of Food Studies. His career highlights include expertise in Newari cuisine, seafood in Nepal, and French cuisine in Dubai. In Australia, he expanded his culinary horizons with several years at renowned establishments. Beyond cooking, Rakesh is an ardent football fan, movie lover, and avid traveler, contributing diverse experiences to his role.`
    },
    
    {
      image:BishnuImage,
      name: "Bishnu Kusi",
      title: 'Director',
      about: `Bishnu, a skilled banker with 10 years of experience in various Nepalese banks, relocated to Australia in 2016 to pursue a more promising career. He holds a Master's degree in Finance from Tribhuvan University and also obtained a Master's degree in Accounting from Central Queensland University in Australia. With experience spanning banking, hospitality, and the service industry, he is currently thriving in his banking career in Australia. Bishnu is an enthusiastic traveler, music aficionado, and a football enthusiast. His expertise has the potential to bring a unique perspective to the company.`
    },
    {
      image:PrabeshImage,
      name:'Prabesh Niraula',
      title:'Director',
      about: `Prabesh is a full-stack engineer with over 10 years of experience, skilled in both front-end and back-end development, and well-versed in cloud technology. He is actively involved in several startup ventures. With a Bachelor's degree in Computer Science and a Master's in Information Systems, Prabesh has a solid educational foundation. His commitment to staying up-to-date is evident, as he is currently pursuing a Master's degree in Artificial Intelligence. Prabesh's extensive expertise in software engineering, coupled with his ongoing education, positions him as a valuable asset in the ever-evolving world of technology.`
    },
    {
      image:PramodImage,
      name:'Pramod Subedi',
      title:'Director',
      about: `Pramod Subedi, Director at Aussie Everest Group Pty. Ltd., brings over 15 years of diverse experience from his move from Nepal to Australia. With a diploma in business management from Australian Pacific College Sydney, he has excelled in the hospitality sector and currently supervises in warehouse and logistics. Known for his honesty, hard work, and adaptability, Pramod values teamwork and thrives in collaborative settings. Outside of work, he enjoys traveling, music, and socializing, adding to his well-rounded persona. His dedication to continuous learning and expertise in management highlight his significant contributions to the company.`
    },
    {
      image:PawanImage,
      name:'Pawan Nepal',
      title:'Director',
      about: `Pawan is a forward-thinking full-stack developer with extensive experience across the software development industry, embodying a blend of technical prowess and leadership in team settings. With over a decade in the field, Pawan's expertise spans design, development, and operational optimization, characterised by his adeptness in frontend, backend, and cloud technologies.  Pawan's academic background includes a Bachelor's degree in Computer Engineering and a Master's degree in Information Technology, underpinning his robust technical skills and strategic approach to software solutions. This combination of education and professional experience positions him as a pivotal contributor to technology projects, leveraging his skills to foster growth and development within the tech industry.`
    }
  ];
  return (
    <Wrapper id="team">
      <div className="lightBg" style={{padding: '50px 0px'}}>
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Our Team</h1>
            <p className="font13">
              Meet the Minds Behind Our Success: Our Exceptional Team.
            </p>
          </HeaderInfo>
          <TablesWrapper className="flexSpaceLeft">
            {members.map(member=>
              <TableBox>
                <Member
                  key={member.name}
                  image={member.image}
                  name={member.name}
                  title={member.title}
                  about={member.about}
                />
              </TableBox>
            )}
          </TablesWrapper>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  padding: 50px 0px 0px;
`;
const HeaderInfo = styled.div`
  margin-bottom: 50px;
  @media (max-width: 860px) {
    text-align: left;
    margin-bottom: 0px;
  }
`;
const TablesWrapper = styled.div`
display: flex;
flex-wrap: wrap;

`;
const TableBox = styled.div`
  flex: 0 0 31%;
  @media (max-width: 860px) {
    flex: 0 0 48%;
  }
  @media (max-width: 600px) {
    flex: 0 0 100%;
  }
`;





