import React from "react";
import styled from "styled-components";
// Components
import FutureProjectBox from "../Elements/FutureProjectBox";
import MortgageImage from "../../assets/img/mortgage.png";
import RentBoatImage from "../../assets/img/rent_boat.png";
import CafeImage from "../../assets/img/cafe.png";

export default function FutureServices() {
  return (
    <Wrapper id="future">
      <div className="whiteBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Future Services</h1>
            <p className="font13">
            Exciting things are on the horizon! Stay tuned for our upcoming launch, where innovation and inspiration collide. Get ready to experience something extraordinary. Keep an eye on this space – big things are coming soon!
            </p>
          </HeaderInfo>
          <div className="row textCenter">
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <FutureProjectBox
                title="Mortgage Broker Service"
                text="Exciting news! Our innovative mortgage finance company is launching soon, and we can't wait to transform your homeownership dreams into reality. At our core, we're dedicated to simplifying the mortgage process, making it accessible and stress-free for all. With competitive rates, tailored solutions, and a commitment to customer satisfaction, we're here to guide you every step of the way."
                date="Coming soon!"
                location="Sydney"
                image={MortgageImage}
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <FutureProjectBox
                title="Rent a Boat"
                text="Get ready for an unforgettable adventure on the water! Our boat rental service is coming soon, bringing you the opportunity to explore picturesque destinations, bask in the sun, and create lasting memories with family and friends. Stay tuned for an amazing experience that's just around the corner!"
                date="Coming soon!"
                location="Sydney"
                image={RentBoatImage}
              />
            </div>
            {/* <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <FutureProjectBox
                title="Cafe"
                text="Get ready to savor the aroma of freshly brewed coffee and indulge in delectable pastries at our charming cafe. With cozy seating, warm ambiance, and a menu bursting with flavor, we're your go-to spot for a delightful coffee experience. Join us soon for a taste of heaven in every sip and a slice of happiness in every bite. Stay tuned for our grand opening!"
                location="Sydney"
                date="Coming soon!"
                image={CafeImage}
              />
            </div> */}
          </div>
          </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  margin-top: -50px;  
  @media (max-width: 860px) {
    margin-top: 50px; 
  }
`;
const HeaderInfo = styled.div`
  margin-bottom: 10px;
`;

